import { useEffect } from 'react'

export default function Bastidores () {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    return (
        <>
        Esta funcionalidade estará disponível em breve! Desculpe o empecilho.
        </>
    );
}